<template>
  <router-link :to="'/app/business_profile/' + user.id">
    <div class="md-layout-item user-list__item md_badge_color head-container">
      
      <md-card class="user-details-container">
        <md-card-header>
          <md-card-header-text >
          <div >
            <v-row>
              <v-col class="image-cont" cols="1" lg="1">
                <md-avatar class="md-large">
                <img
                :src="imageUrl"
                @error="
                  $event.target.src = require('../../assets/person.png')
                "
              />
            </md-avatar>
              </v-col>
              <v-col class="details-cont user-con" cols="2" lg="2">
                  <div  v-if="user.role_id === 3">
                    <span class="buyer-cont">Buyer</span>
                  </div>
                  <div  v-if="user.role_id === 4">
                    <span class="supplier-cont">Supplier</span>
                  </div>
              </v-col>
            <v-col class="details-cont" cols="3" lg="3">
              <span class="font-weight-bold ">{{user.full_name}}</span>
            </v-col>
              <v-divider  vertical class="divider-cont"></v-divider>
            
            <v-col class="details-cont" cols="3" lg="3">
              <span class="font-weight-bold">{{ user.email }}</span>
            </v-col>
              <v-divider  vertical class="divider-cont"></v-divider>
            <v-col class="details-cont" cols="3" lg="2">
              <span class="font-weight-bold">{{user.brand_name}}</span>
              <span v-if="user.reference_name"> ({{ user.reference_name }})</span>
            </v-col>
          </v-row>
          </div>
          </md-card-header-text>
        </md-card-header>
      </md-card>
    </div>
  </router-link>
</template>

<script>
import restAdapter from "../../restAdapter";

export default {
  name: "UserItem",
  props: {
    user: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    }
  },
  data: () => ({
    imageUrl: require(`../../assets/person.png`),
  }),
  mounted() {
    setTimeout(() => {
      this.getProfilePicture();
    }, 100 + 10 * this.index)
  },
  methods: {
    async getProfilePicture(){
      const res = await restAdapter.getWithOutCache("/api/user/image_show/" + this.user.id);
        if(res.data.image){
          this.imageUrl = res.data.image; 
        }
    }
  },
};
</script>

<style lang="scss" scoped>
.user-list {
  .md-title,
  .md-subhead {
    text-align: left;
  }

  a:not(.md-button):hover {
    text-decoration: none;
  }

  .md-card.md-theme-default:hover {
    text-decoration: none;
    background-color: #f3f1f1;
  }

  .md-card-actions.md-alignment-right {
    justify-content: space-between;
  }
}


.color_stroke::v-deep .md-progress-spinner-circle{
    stroke: #492a67 !important;
}

.list_color.md-selected::v-deep .md-list-item-text{
    color: #fa735f !important;
}

.md-field.md-theme-default.md-focused label{
  color: #582963 !important;
}

 .md_badge_color::v-deep .md-badge.md-theme-default.md-primary {
    color: #fff !important;
    background-color: #492a67  !important;
}

.md_badge_color::v-deep .md-badge.md-theme-default {
    color: #fff;
    background-color: #fa735f !important;
}

.md-field.md-theme-default:before{
    background-color: #c6a8ec !important;
}

.details-cont{
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 116.1%;
    display: flex;
    align-items: center;
    color: #442D65; 
}
.buyer-cont{
    box-sizing: border-box;
    width: 100px;
    height: 32px;
    left: 158px;
    top: 30px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-left: 15px;
    background: rgba(71, 48, 104, 0.1);
    border-radius: 81.2308px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 116.1%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #442D65;
    justify-content:center
}
.supplier-cont{
    box-sizing: border-box;
    width: 100px;
    height: 32px;
    left: 158px;
    top: 30px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    background: #FFF2F0;
    border-radius: 81.2308px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 116.1%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #EA7A66;
}
.user-details-container{
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
    border-radius: 7px;
    margin:auto;
    height: 90px;
}
.divider-cont{
  width: 0px;
  height: 48px;
  left: 889px;
  margin-top: 21px;
  border: 1px solid rgba(71, 48, 104, 0.33)
}
.user-container{
  color:#492a67 !important;
}
.card-container{
  background-color: greenyellow !important;
  justify-content: left;
}
.image-cont{
  display: grid;
  justify-content: center;
  align-items:center;
  text-align: center;
}
</style>
