<template>
  <v-container id="users" fluid tag="section">
    <v-row >
      <v-col cols="12">
        <v-container class="py-0">
          <form novalidate>
            <md-card-content class="card-field-con">
              <v-row class="inquiry-search-bar">
                <v-col xl="4" lg="4" xs="6">
                  <md-field class="search-group">
                    <label for="search-query">Search</label>
                    <md-input
                      name="search"
                      id="search"
                      v-model="query"
                      v-on:input="debounceSearch"
                    />
                  </md-field>
                </v-col>

                <v-col xl="4" lg="4" xs="6">
                  <md-field class="search-group">
                    <label for="user_type">User Type</label>
                    <md-select
                      data-cy="user-type"
                      name="user-type"
                      id="user-type"
                      v-model="userType"
                      placeholder="User Type"
                      :disabled="loading"
                      @input="userTypeSearch"
                    >
                      <md-option class="list_color" value="all">All</md-option>
                      <md-option class="list_color" value="customer">Buyer</md-option>
                      <md-option class="list_color" value="supplier">Supplier</md-option>
                    </md-select>
                  </md-field>
                </v-col>
                <v-col class="clear-btn-cont" xl="4" lg="4" xs="6">
                  <v-btn
                    class="ma-2 color_class"
                    dark
                    outlined
                    id="clear-filters"
                    :disabled="loading"
                    @click="clearFilters"
                  >
                    Clear filters
                  </v-btn>
                </v-col>
              </v-row>
            </md-card-content>
          </form>
          <div class="card-container">
            <v-card class="card-container">
              <v-row class="user-container" no-gutters>
                <v-col cols="1" lg="1" class="sub-title">Avatar</v-col>
                <v-col cols="2" lg="2" class="sub-title">User Type</v-col>
                <v-col cols="3" lg="3" class="sub-title">Full Name </v-col>
                <v-col cols="3" lg="3" class="sub-title">Email</v-col>
                <v-col cols="3" lg="2" class="sub-title">Business Name</v-col>
              </v-row>
            </v-card>
          </div>
          <v-row class="user-list" v-if="!loading">
              
            <v-col
              cols="12"
              v-for="(user, index) in users"
              :key="user.id"
              class="inquiry"
            >
              <UserItem :user="user" :index="index" />
            </v-col>
            <Pagination
              :current-page="currentPage"
              :total-count="totalCount"
              @load-page="loadPage"
              v-if="users.length"
            />
          </v-row>
          <div v-if="loading" class="my-5">
            <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
          </div>
          <v-row v-if="!loading && users.length === 0">
            <v-col cols="12">
              No user found.
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import restAdapter from "../../restAdapter";
import UserItem from "./UserItem.vue";
import auth from "../../auth";
import Pagination from "../../components/Pagination";
import * as conf from "../../conf.yml";
import debounce from "lodash/debounce";

export default {
  name: "CustomersAndSuppliersList",
  components: {
    UserItem,
    Pagination,
  },
  data: () => ({
    users: [],
    loading: false,
    clearing: false,
    query: "",
    userType: "all",
    currentPage: 1,
    totalCount: 0,
  }),
  mounted() {
    this.baseUrl = conf.default.server.host;
    this.getUsers();
  },
  methods: {
    async getUsers(page) {
      if (!page) {
        this.currentPage = 1;
      }
      this.loading = true;
      let url = "/api/view_users?page=" + this.currentPage;
      url +=
        "&query=" +
        this.query +
        "&user_type=" +
        (this.userType ? this.userType : "");

      restAdapter.get(url).then(async(response) => {
        const updatedUsers = response.data.users.data;
        this.users = updatedUsers;
        this.totalCount = response.data.users.total;
        this.loading = false;
      });
    },
    loadPage(page) {
      this.currentPage = page;
      this.getUsers(page);
    },
    debounceSearch: debounce(function() {
      if (!this.clearing) {
        this.getUsers();
      }
    }, 1000),
    userTypeSearch() {
      if (!this.clearing) {
        this.getUsers();
      }
    },
    clearFilters() {
      this.clearing = true;
      this.userType = "all";
      this.query = "";
      this.getUsers().then(() => {
        this.clearing = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-list {
  .md-title,
  .md-subhead {
    text-align: left;
  }

  a:not(.md-button):hover {
    text-decoration: none;
  }

  .md-card.md-theme-default:hover {
    text-decoration: none;
    background-color: #f3f1f1;
  }

  .md-card-actions.md-alignment-right {
    justify-content: space-between;
  }
}

.inquiry-search-bar{
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.color_stroke::v-deep .md-progress-spinner-circle{
    stroke: #492a67 !important;
}

.list_color.md-selected::v-deep .md-list-item-text{
    color: #492a67 !important;
    
}

.md-field.md-theme-default.md-focused label{
  color: #582963 !important;
}

 .md_badge_color::v-deep .md-badge.md-theme-default.md-primary {
    color: #fff !important;
    background-color: #492a67  !important;
}

.md_badge_color::v-deep .md-badge.md-theme-default {
    color: #fff;
    background-color: #fa735f !important;
}

.md-field.md-theme-default:before{
    background-color: #473068 !important;
}
.md-field.md-theme-default:after {
    background-color: rgb(61 26 221 / 42%);
}
.search-group{
  margin: 0%;
}

.clear-btn{
    border: 1px solid #473068;
    color: #442D65;
}
.text-simple {
    text-transform: none;
}
.card-container{
    background-color: $success;
    border-radius: 7px;
    box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
}
.user-container{
    color:#492a67 !important;
    margin: 0px 16px;
    height: 50px;
    align-items: center;
}
.sub-title{
    margin-left: 3px;
    text-align:left;
}
.clear-btn-cont{
    display: flex;
    justify-content: flex-end;
}
.card-field-con{
    margin: 0px -5px;
}  
.color_class {
    border: 1px solid #473068;
    color: #442D65;
}

</style>
